// @ts-check
import React from "react"
import axios from "axios"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card, { CardTitle } from "../components/card"
import Input, { Label } from "../components/input"
import { FUNCTIONS_ENDPOINT } from "../config"
import { SendingButton } from "../components/button"
import colors from "../styles/colors"
import Typography, { typographyComponents } from "../components/typography"
import { identify, trackEvent } from "../utils/trackEvent"
import { trackError } from "../utils/segment"

const Form = styled.form`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 100px;
`
const FormButton = styled(SendingButton)`
  align-self: flex-start;
`
const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`
const FormInput = styled(Input)`
  max-width: 450px;
`
const FormTextarea = styled(Input)`
  resize: vertical;
  min-height: 200px;
  max-width: 650px;
`.withComponent("textarea")

const Status = styled(typographyComponents.caption)`
  color: ${colors.light};
  margin-left: 20px;
`

const ContactUsForm = () => {
  const [name, setName] = React.useState("")
  const [company, setCompany] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [text, setText] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const [status, setStatus] = React.useState(
    /** @type {undefined | "success" | "failure" } */ (undefined)
  )

  const onSubmit = React.useCallback(
    async e => {
      e.preventDefault()
      setIsSending(true)
      try {
        trackEvent({
          category: "contact",
          label: "contact form details submitted",
          siteSection: "contact_us_form",
        })
        identify(email, company)
        const response = await axios.post(`${FUNCTIONS_ENDPOINT}/contact-us/`, {
          name,
          company,
          email,
          text,
        })

        if (response.data.status !== "success") {
          throw new Error("response was not successful")
        }
        setStatus("success")
        setName("")
        setCompany("")
        setText("")
        setEmail("")
      } catch (err) {
        setStatus("failure")
        trackError(err)
      } finally {
        setIsSending(false)
      }
    },
    [company, email, name, text]
  )

  return (
    <Form onSubmit={onSubmit}>
      <Label as="label" htmlFor="contact-name">
        Name
      </Label>

      <FormInput
        id="contact-name"
        variant="darkgrey"
        onChange={e => setName(e.target.value)}
        value={name}
        required
        placeholder="John Smith"
      />

      <Label as="label" htmlFor="contact-email">
        Email
      </Label>

      <FormInput
        id="contact-email"
        variant="darkgrey"
        type="email"
        onChange={e => setEmail(e.target.value)}
        value={email}
        required
        placeholder="john.smith@company.com"
      />

      <Label as="label" htmlFor="contact-company">
        Company (optional)
      </Label>
      <FormInput
        id="contact-company"
        variant="darkgrey"
        onChange={e => setCompany(e.target.value)}
        value={company}
        placeholder="Company"
      />

      <hr style={{ height: 14, visibility: "hidden" }} />

      <Label as="label" htmlFor="contact-text">
        How can we help?
      </Label>
      <FormTextarea
        variant="darkgrey"
        id="contact-text"
        onChange={e => setText(e.target.value)}
        value={text}
      />

      <FormFooter>
        <FormButton
          type="submit"
          variant="primary"
          disabled={isSending}
          isSending={isSending}
        >
          Send
        </FormButton>

        {status === "success" && <Status>Message received!</Status>}
        {status === "failure" && (
          <Status>
            A technical error occurred. Please try sending us an
            email&nbsp;instead.
          </Status>
        )}
      </FormFooter>
    </Form>
  )
}

const CardNoPadding = styled(Card)`
  padding-top: 20px;
`
const Link = styled.a`
  color: ${colors.primary};
  text-decoration: underline;
`

const ContactUsPage = () => (
  <Layout bg="white">
    <SEO title="Contact us" />

    <CardNoPadding variant="white">
      <CardTitle variant="h1" as="h1" maxWidth={480}>
        Contact us
      </CardTitle>

      <ContactUsForm />

      <Typography variant="bodyLarge">
        You can also send us an email at{" "}
        <Link href="mailto:contact@mavenoid.com">contact@mavenoid.com</Link>
      </Typography>
    </CardNoPadding>
  </Layout>
)

export default ContactUsPage
